<template>
	<div class="order--info-modal">
		<div class="modal--cover" @click.prevent="closeOrder"></div>
		<div class="modal--wrapper">
			<div class="cont">
				<div class="button" @click.prevent="closeOrder">
					<span class="link--mouse-small" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">{{$t('generale.chiudi')}}</span>
				</div>
				<div class="form">
					<div class="fixed--items">
						<div class="fixed--item">
							<span class="label">{{$t('generale.form.nome')}}</span>
							<span class="value">{{orderName}}</span>
						</div>
						<div class="fixed--item">
							<span class="label">{{$t('generale.form.rag_sociale')}}</span>
							<span class="value">{{orderRSoc}}</span>
						</div>
						<div class="fixed--item">
							<span class="label">{{$t('generale.form.p_iva')}}</span>
							<span class="value">{{partitaIva}}</span>
						</div>
						<div class="fixed--item">
							<span class="label">{{$t('generale.form.indirizzo')}}</span>
							<span class="value">{{completeAddress}}</span>
						</div>
					</div>
					<form @submit.prevent="sendOrder">
						<div class="form--item">
							<label for="product-info-mail">{{$t('generale.form.mail')}}*</label>
							<input type="email" id="product-info-mail" v-model="orderMail">
							<span class="error" v-if="fieldErrors.mail">{{fieldErrors.mail}}</span>
						</div>
						<div class="form--item">
							<label for="product-info-tel">{{$t('generale.form.tel')}}</label>
							<input type="text" id="product-info-tel" v-model="orderTel">
						</div>
						<div class="form--item">
							<label for="product-info-messaggio">{{$t('generale.form.note')}}</label>
							<textarea id="product-info-messaggio" v-model="orderMessage"></textarea>
						</div>
						<p class="privacy--margin">{{ $t("generale.form.privacy_order_1") }}</p>
						<div class="form--item privacy" :class="{ 'error' : fieldErrors.privacy }">
							<input type="checkbox" v-model="orderPrivacy" id="product-info-privacy">
							<div class="check" :class="{ 'selected' : orderPrivacy }"></div>
							<label for="product-info-privacy">{{ $t("generale.form.privacy_order_2") }}</label>
						</div>
						<button class="link--mouse-small" type="submit" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">{{$t('carrello.richiedi')}}</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import gsap from 'gsap'
import axios from 'axios'
import { mapActions } from 'vuex';
export default {
	data() {
		return {
			orderName: '',
			orderMail: '',
			orderTel: '',
			orderMessage: '',
			orderRSoc: '',
			partitaIva: '',
			completeAddress: '',
			orderPrivacy: false,
			//eslint-disable-next-line
			reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
			fieldErrors: {
				mail: '',
				privacy: false
			}
		}
	},
	props: {
		products: {
			type: Array,
			required: true,
		},
		open: {
      type: Boolean,
      required: true
    }
	},
  watch: {
    open: {
      deep: true,
      handler: function(val){
        if(val){					
          this.openOrder();
        }else{
					this.closeOrder();
        }
      }
    }
  },
	methods: {
		...mapActions(['getUserData']),
		openOrder() {
			this.$formLoaderAnimation = false
			gsap.to(this.$el, { duration: 0, width: '100%' });
			gsap.to(this.$el.querySelector('.modal--cover'), { duration: 1, width: '100%', ease: 'power4.out' });
			gsap.to(this.$el.querySelector('.modal--wrapper'), { duration: 1, x: 0, ease: 'power4.out' });
		},
		closeOrder() {
			this.$formLoaderAnimation = true
			gsap.to(this.$el.querySelector('.modal--cover'), { duration: 1, width: 0, ease: 'power4.out' });
			gsap.to(this.$el.querySelector('.modal--wrapper'), { duration: 1, x: '100%', ease: 'power4.out' });
			gsap.to(this.$el, { duration: 0, delay: 1, width: 0 });
			this.$emit('orderClosing', false)
		},
		sendOrder() {
			this.fieldErrors.mail = ''
			this.fieldErrors.privacy = false

			if(this.orderMail.trim() != '' && this.validateEmail(this.orderMail.trim()) && this.orderPrivacy){
				gsap.to(this.$el.querySelector('.modal--wrapper'), {duration: 0.5, x: '100%', ease: 'power2.out', onComplete: () => {
					gsap.to(this.$el.querySelector('.modal--cover'), { duration: 0, autoAlpha: 0 });
					this.$formLoaderVisible = true
				}})

				let order = {
					id: this.currentUserID,
					name: this.orderName,
					mail: this.orderMail,
					tel: this.orderTel,
					r_soc: this.orderRSoc,
					p_iva: this.partitaIva,
					address: this.completeAddress,
					notes: this.orderMessage,
					products: this.products,
					lang: localStorage.getItem('lang')
				}
				axios
					.post(process.env.VUE_APP_API_URL + '/wl/v1/orderMail', order)
					.then(res => {
						console.log(res)
						this.$formLoaderVisible = false
						this.$emit('orderPlaced', true);
					});
			}else{
				if(this.orderMail.trim() == '' || !this.validateEmail(this.orderMail.trim())){
					if(this.orderMail.trim() == ''){
						this.fieldErrors.mail = this.$t('contatti.campo_vuoto')
					}else{
						this.fieldErrors.mail = this.$t('signup.mail_errata')
					}					
				}
				if(!this.orderPrivacy){
					this.fieldErrors.privacy = true
				}
			}
		},
		validateEmail(email){
      return this.reg.test(email);
		}
	},
	mounted() {
		//this.openOrder()
		this.getUserData(this.currentUserID).then((res) => {
			//console.log(res)
			if(res.data.name != '' && res.data.surname != ''){
				this.orderName = res.data.name + ' ' + res.data.surname
			}
			if(res.data.mail != ''){
				this.orderMail = res.data.mail;
			}
			if(res.data.telefono != ''){
				this.orderTel = res.data.telefono;
			}
			if(res.data.ragione_sociale != ''){
				this.orderRSoc = res.data.ragione_sociale;
			}
			if(res.data.partita_iva != ''){
				this.partitaIva = res.data.partita_iva;
			}
			if(res.data.indirizzo != ''){
				this.completeAddress = res.data.indirizzo + ' - ' + res.data.cap + ', ' + res.data.provincia + ' (' + res.data.nazione + ')';
			}
		})
	}
}
</script>

<style lang="scss">
.order--info-modal {
	position: fixed;
	top: 0;
	right: 0;
	width: 0%;
	height: 100vh;
	z-index: 200;
	@include mobile {
		z-index: 98;
	}
	.modal--cover {
		position: absolute;
		top: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background-color: rgba($white, 0.8);
	}
	.modal--wrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: 40vw;
		height: 100%;
		background-color: $white;
		border-left: 1px solid $black;
		transform: translateX(100%);
		@include mobile {
			width: 100vw;
			overflow: scroll;
		}
		.cont {
			position: relative;
			width: 100%;
			padding: 80px 1vw 0 1vw;
			box-sizing: border-box;
			@include mobile {
				padding: 60px 5vw 10vh 5vw;
			}
			.button {
				position: relative;
				width: 100%;
				height: 40px;
				border-top: 1px solid $black;
				border-bottom: 1px solid $black;
				display: flex;
				justify-content: space-between;
				align-items: center;
				z-index: 6;
				box-sizing: border-box;
				span {
					font-family: 'Suisse Int';
					font-weight: 400;
					color: $black;
					font-size: 14px;
					line-height: 14px;
					margin: 0;
					text-transform: uppercase;
				}
			}
			.form {
				padding-top: 2vh;
				.fixed--items {
					position: relative;
					width: 100%;
					.fixed--item {
						position: relative;
						width: 100%;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-bottom: 10px;
						.label {
							font-family: 'Suisse Int';
							font-weight: 500;
							color: $black;
							font-size: 14px;
							line-height: 14px;
							margin: 0;
							text-transform: uppercase;
						}
						.value {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 14px;
							line-height: 14px;
							margin: 0;
							margin-left: 10px;
						}
					}
				}
				form {
					padding-top: 3vh;
					.privacy--margin {
						position: relative;
						width: 100%;
						height: auto;
						margin: 20px 0;
						font-family: 'Suisse Int';
						color: $black;
						font-size: 12px;
						line-height: 14px;
					}
					.form--item {
						position: relative;
						width: 100%;
						display: inline-flex;
						flex-direction: column;
						margin-bottom: 5vh;
						box-sizing: border-box;
						label {
							font-family: 'Suisse Int';
							text-transform: uppercase;
							color: $black;
							font-size: 14px;
							display: block;
							margin-bottom: 10px;
							@include mobile {
								margin-bottom: 0;	
							}
						}
						input {
							position: relative;
							width: 100%;
							font-family: 'Suisse Int';
							color: $black;
							font-size: 20px;
							line-height: 30px;
							border-radius: 0;
							padding: 10px;
							border: 0;
							border-bottom: 1px solid $black;
							box-sizing: border-box;
							&:focus {
								outline: none;
							}
							@include mobile {
								font-size: 16px;
								line-height: 20px;
							}
						}
						textarea {
							position: relative;
							width: 100%;
							height: 10vh;
							font-family: 'Suisse Int';
							color: $black;
							font-size: 20px;
							line-height: 30px;
							border-radius: 0;
							padding: 10px;
							border: 0;
							border: 1px solid $black;
							box-sizing: border-box;
							resize: none;
							&:focus {
								outline: none;
							}
							@include mobile {
								font-size: 16px;
								line-height: 20px;
							}
						}
						&.half {
							width: 50%;
							&.left {
								padding-right: 1vw;
							}
							&.right {
								padding-left: 1vw;
							}
						}
						&.third {
							width: calc(100% / 3);
							&.left {
								padding-right: 1vw;
							}
							&.middle {
								padding: 0 1vw;
							}
							&.right {
								padding-left: 1vw;
							}
						}
						&.twothird {
							width: calc(200% / 3);
							&.left {
								padding-right: 1vw;
							}
						}
						&.privacy {
							position: relative;
							width: 100%;
							display: flex;
							justify-content: flex-start;
							flex-direction: row;
							input {
								display: none;
							}
							.check {
								position: relative;
								width: 20px;
								height: 20px;
								border: 1px solid $black;
								box-sizing: border-box;
								flex-shrink: 0;
								&.selected {
									&:after {
										content: '';
										width: 12px;
										height: 12px;
										position: absolute;
										background-color: $black;
										top: 3px;
										left: 3px;
									}
								}
							}
							label {
								line-height: 20px;
								color: $black;
								font-family: 'Suisse Int';
								text-transform: uppercase;
								margin-left: 10px;
							}
							&.error {
								.check {
									border-color: $red
								}
								label {
									color: $red;
								}
							}
						}
						.showPass {
							position: absolute;
							bottom: 25px;
							right: 10px;
							width: 20px;
							transform: translateY(50%);
							svg {
								fill: $black;
							}
						}
						.error {
							position: absolute;
							bottom: -20px;
							left: 0;
							width: calc(100% - 1vw);
							text-align: right;
							font-family: 'Suisse Int';
							color: $red;
							font-size: 12px;
							line-height: 20px;
						}
					}
					button {
						background-color: black;
						border: 1px solid $black;
						font-family: 'Suisse Int';
						font-weight: 400;
						color: $white;
						font-size: 12px;
						line-height: 12px;
						text-transform: uppercase;
						border-radius: 50px;
						padding: 15px 5vw;
						width: auto;
						transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
						&:hover {
							background-color: transparent;
							color: $black;
							transition: background-color .35s ease-in-out 0s, color .35s ease-in-out 0s;
						}
					}
				}
			}
		}
	}
}
</style>