<template>
	<div class="products--header" :class="isFiltersShowed ? 'withFilters' : ''">
		<span v-show="isFiltersShowed" class="link--mouse-small" @click.prevent="toggleFilters">{{$t('header.filter')}}</span>
		<router-link :to="'/' + $i18n.locale + '/' + $t('header.cart_link')" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">{{$t('header.cart')}} ({{productsOnCart}})</router-link>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	data() {
		return {
			opened: false,
			productsOnCart: 0
		}
	},
	props: {
		isFiltersShowed: {
			type: Boolean
		},
		isQuantityUpdating: {
			type: Number
		}
	},
	watch: {
		isQuantityUpdating: function (val) {
			if(val == 1){
				this.productsOnCart++
			}else if(val == 2){
				this.productsOnCart--
			}else if(val == 3){
				this.productsOnCart = 0
			}
		}
	},
	methods: {
		toggleFilters() {
			if(this.opened){
				this.opened = false
				this.$emit('toggle', false)
			}else{
				this.opened = true
				this.$emit('toggle', true)
			}
		},
		countCart() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wl/v1/cart/count/' + this.currentUserID)
				.then(response => {
					this.productsOnCart = response.data
					/* this.productsOnCart = response.data;
					this.total = parseInt(response.headers['x-wp-total'])
					this.total_pages = parseInt(response.headers['x-wp-totalpages']) */
					//console.log(this.products, this.total, this.total_pages)
				});
		}
	},
	beforeMount() {
		this.countCart()
	}
}
</script>

<style lang="scss">
.products--header {
	position: fixed;
	top: 80px;
	left: 1vw;
	width: calc(100% - 2vw);
	height: 40px;
	border-top: 1px solid $black;
	border-bottom: 1px solid $black;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	background-color: $white;
	z-index:7;
	@include mobile {
		top: 60px;
		left: 5vw;
		width: calc(100% - 10vw);
	}
	span {
		font-family: 'Suisse Int';
		font-weight: 400;
		color: $black;
		font-size: 14px;
		line-height: 14px;
		margin: 0;
		text-transform: uppercase;
	}
	a {
		font-family: 'Suisse Int';
		font-weight: 400;
		color: $black;
		font-size: 14px;
		line-height: 14px;
		margin: 0;
		text-transform: uppercase;
		text-decoration: none;
	}
	&.withFilters {
		justify-content: space-between;
	}
}
</style>