<template>
	<div class="cart--row" :product-id="product" >
		<DeleteSVG @click.native="removeFromCart(product)" class="mobile" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')"/>
		<div class="item-image" v-if="Object.keys(prd).length">
			<getACFById :product_id="prd[0].id" field="galleria" field_type="img" />
		</div>
		<div class="item-image" v-else-if="Object.keys(prdNoTrad).length">
			<getACFById :product_id="prdNoTrad.id" field="galleria" field_type="img" />
		</div>
		<div class="item-desc" v-if="Object.keys(prd).length">
			<h2 v-if="prd[0].title">{{prd[0].title.rendered}}</h2>
			<span v-if="prd[0].content">{{strippedContent(prd[0].content.rendered)}}</span>
			<DeleteSVG @click.native="removeFromCart(product)" class="link--mouse-small desktop" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')"/>
		</div>
		<div class="item-desc" v-else-if="Object.keys(prdNoTrad).length">
			<h2 v-if="prdNoTrad.title">{{prdNoTrad.title.rendered}}</h2>
			<span v-if="prdNoTrad.content">{{strippedContent(prdNoTrad.content.rendered)}}</span>
			<DeleteSVG @click.native="removeFromCart(product)" class="link--mouse-small desktop" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')"/>
		</div>
	</div>
</template>

<script>
import getACFById from '@/components/getACFById';
import DeleteSVG from '@/components/elements/DeleteSVG';
import axios from 'axios'
import gsap from 'gsap'
export default {
	data() {
		return {
			prd: {},
			prdNoTrad: {},
			current_language: this.$route.params.locale
		}
	},
	props: {
		product: {
			type: Number,
			required: true,
		}
	},
	components: {
		getACFById,
		DeleteSVG
	},
	watch: {
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.getProduct()
		}
	},
	methods: {
		removeFromCart(id){
			this.$emit('updatePrdQnt', 0)
			this.$formLoaderVisible = true
			let el = document.querySelector('.cart--row[product-id="' + id + '"]');
			let data = {
				'user': this.currentUserID,
				'product': id
			}
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/cart/remove/', data)
				.then(response => {
					if(response.data.success){
						this.$emit('updatePrdQnt', 2)
						this.$formLoaderVisible = false
						gsap.to(el, { duration: 1, height: 0, autoAlpha: 0, padding: 0, ease: 'power4.out', onComplete: () => {
							el.remove()
						}});
					}
				});
		},
		getProduct() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/?include=' + this.product + '&lang=' + this.current_language)
				.then(response => {
					this.prd = response.data;
					if(this.prd.length == 0){
						axios
						.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/' + this.product)
						.then(response => {
							this.prdNoTrad = response.data;							
						});
					}
					
				});
		},
    strippedContent(str) {
      let regex = /(<([^>]+)>)/ig;
      return str.replace(regex, "");
    }
	},
	beforeMount() {
		this.getProduct()
	}
}
</script>

<style lang="scss">
.cart--row {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	padding: 1vw 0;
	border-bottom: 1px solid $black;
	overflow: hidden;
	@include mobile {
		padding: 0;
		border-bottom: 0;
		width: 50%;
		flex-direction: column;
		margin-bottom: 5vh;
	}
	.item-image {
		position: relative;
		width: 15vw;
		@include mobile {
			width: 100%;
			padding: 0 5vw;
			box-sizing: border-box;
		}
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	.item-desc {
		position: relative;
		margin-left: 1vw;
		width: calc(100% - 15vw);
		@include mobile {
			width: 100%;
			margin-left: 0;
			padding: 0 5vw;
			box-sizing: border-box;
		}
		h2, span {
			margin-left: 10px;
			text-transform: uppercase;
			font-family: 'Suisse Int';
			font-size: 14px;
			line-height: 14px;
			font-weight: 500;
			color: $black;			
			@include mobile {
				margin-left: 0;
				font-size: 12px;
				line-height: 14px;
			}
		}
		.desktop {
			@include mobile {
				display: none;
			}
		}
		.mobile {
			display: none;
			@include mobile {
				display: flex;
			}
		}
	}
	&:last-of-type {
		border-bottom: 0;
	}
}
</style>