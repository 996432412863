<template>
	<div class="cart">
		<ProductsHeader :isFiltersShowed="false" :isQuantityUpdating="isQuantityUpdating" />
		<div class="cart--list" v-if="!isLoading">
			<div class="list--wrapper" v-if="cartItems.length > 0">
				<CartItem v-for="(el, index) in cartItems" :key="index" :product="parseInt(el)" @updatePrdQnt="updatePrdQnt" />
			</div>
			<div class="no--cart-products" v-else>
				<h2>{{$t('carrello.vuoto')}}</h2>
			</div>
		</div>
		<div class="finish--order" v-if="!isLoading && cartItems.length > 0" @click.prevent="openOrderForm" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">
			<span>{{$t('carrello.richiedi')}}</span>
		</div>
		<PlaceOrder :open="isOrderOpen" :products="cartItems" @orderClosing="openInfo" @orderPlaced="placedAnimation" />
	</div>
</template>

<script>
import ProductsHeader from '@/components/ProductsHeader';
import PlaceOrder from '@/components/PlaceOrder';
import CartItem from '@/components/CartItem';
import axios from 'axios';
import gsap from 'gsap';
export default {
	data() {
		return {
			isQuantityUpdating: 0,
			cartItems: [],
			isLoading: true,
			isOrderOpen: false,
			current_language: this.$route.params.locale
		}
	},
	components: {
		ProductsHeader,
		CartItem,
		PlaceOrder
	},
	metaInfo() {
		return{
			title: this.$t('head.title.cart'),
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		placedAnimation(val){
			if(val){
				gsap.to(document.querySelectorAll('.list--wrapper .cart--row'), {duration: 0.5, stagger: -0.2, autoAlpha: 0, ease: 'power2.out', onComplete: () => {
					this.cartItems = []
					this.isQuantityUpdating = 0
					this.updatePrdQnt(3);
				}})
			}
		},
		openInfo(val){
			if(val){
				this.isOrderOpen = true
			}else{
				this.isOrderOpen = false
			}
    },
		openOrderForm() {
			this.isOrderOpen = true
		},
		updatePrdQnt(val) {
			this.isQuantityUpdating = val
		},
		getCartItems(){
			let data = {
				'user': this.currentUserID,
				'lang': this.current_language
			}
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/cart/getItems/', data)
				.then(response => {
					this.isLoading = false
					if(response.data.success){			
						this.cartItems = response.data.list
					}
				});
		}
	},
	watch: {
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.getCartItems()
		}
	},
	beforeMount(){
		this.getCartItems()
	}
}
</script>

<style lang="scss">
.cart {
	position: relative;
	padding: 120px 1vw 0 1vw;
	@include mobile {
		padding-left: 5vw;
		padding-right: 5vw;
	}
	.cart--list {
		position: relative;
		width: 100%;
		height: auto;
		.no--cart-products {
			position: relative;
			width: 100%;
			text-align: center;
			padding: 1vw 0;
			h2 {
				margin: 0;
				font-family: 'Suisse Int';
				font-weight: 500;
				color: $black;
				font-size: 1.5vw;
				line-height: 1.5vw;
			}
		}
		.list--wrapper {
			@include mobile {
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
	.finish--order {
		position: fixed;
		bottom: 1vw;
		right: 1vw;
		width: auto;
		background-color: $black;
		border-radius: 50px;
		padding: 15px 1vw;
		text-align: center;
		span {
			font-family: 'Suisse Int';
			font-weight: 400;
			color: $white;
			font-size: 12px;
			line-height: 12px;
			text-transform: uppercase;
		}
		@include mobile {
			position: relative;
			padding: 15px;
			bottom: auto;
			right: auto;
		}
	}
}
</style>