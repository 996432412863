<template>
	<div>
		<div v-if="data">
			<img v-if="field_type == 'img' && pending" src="@/assets/images/product_dummy.jpg">
			<img v-if="field_type == 'img' && !pending" :src="data[0]">
		</div>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	data() {
    return {
      pending: true,
      error: false,
      data: null
    };
  },
	props: {
		product_id: { type: Number, required: true },
		field: { type: String, required: false },
		field_type: { type: String, required: false },
	},
	watch: {
    product_id: {
      handler() {
        this.requestData();
      },
      deep: true
    }
  },
  mounted() {
    this.requestData();
  },
  methods: {
    async requestData() {
      this.pending = true;
      try {
				const { data } = await axios.get(process.env.VUE_APP_API_URL + '/wl/v1/acf/getfieldbyid/' + this.product_id + '/?field=' + this.field);
        this.data = data;
        this.error = false;
      } catch (e) {
        this.data = null;
        this.error = e;
      }
      this.pending = false;
    }
  },
  render() {
    return this.$scopedSlots.default({
      pending: this.pending,
      error: this.error,
      data: this.data
    });
  }
}
</script>

<style>

</style>